import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'user-admin-main-view',
  computed: {
    ...mapGetters({
      basicCountries: 'setting/basicCountries'
    })
  },
  created () {
    this.changeSkip(0)
    if (this.$route.name !== 'user.admins.create') {
      this.fetchCurrentUser({ id: this.$route.params.id })
    }
    if (!this.basicCountries.length) {
      this.fetchBasicCountries()
    }
  },
  methods: {
    ...mapActions({
      fetchBasicCountries: 'setting/GET_BASIC_COUNTRIES',
      fetchCurrentUser: 'userAdmins/GET_LIST_ITEM'
    }),
    ...mapMutations({
      changeSkip: 'courses/COURSES_LIST_CHANGE_SKIP',
      resetPermissions: 'userAdmins/RESET_USER_PERMISSIONS'
    })
  },
  destroyed () {
    this.resetPermissions([])
  }
}
